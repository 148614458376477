<template lang="pug">
    article#contato
        div.wrapper.flex
            div.contato
                h2 ENTRE EM CONTATO
                p.content
                    | Quer saber mais sobre a GT Company? 
                p.content
                    | Envie uma mensagem pelos nossos canais ou preenchendo os campos ao lado.
            
            div.formulario
                form(@submit.prevent="sendFormulario")
                    div(:class="{error: errors.nome}").input.required
                        input(type="text", v-model="formulario.nome", name="input_nome", @blur="removeError('nome')")#input_nome
                        label(for="input_nome", :class="{ativo: formulario.nome}") Nome:
                        span(v-if="errors.nome").messageError {{ errors.nome }}

                    div(:class="{error: errors.email}").input.required
                        input(type="email", v-model="formulario.email", name="input_email", @blur="removeError('email')")#input_email
                        label(for="input_email", :class="{ativo: formulario.email}") E-mail:
                        span(v-if="errors.email").messageError {{ errors.email }}

                    div(:class="{error: errors.telefone}").input.required
                        input(type="tel", v-model="formulario.telefone", name="input_telefone", @blur="removeError('telefone')", v-mask="['(##) ####-####', '(##) #####-####']")#input_telefone
                        label(for="input_telefone", :class="{ativo: formulario.telefone}") Telefone:
                        span(v-if="errors.telefone").messageError {{ errors.telefone }}

                    div(:class="{error: errors.mensagem}").input.required
                        textarea(v-model="formulario.mensagem", name="input_mensagem", @blur="removeError('mensagem')")#input_mensagem
                        label(for="input_mensagem", :class="{ativo: formulario.mensagem}") Mensagem:
                        span(v-if="errors.mensagem").messageError {{ errors.mensagem }}

                    div.button
                        button(type="submit", v-html="buttonText", :disabled="buttonDisabled")

            div.endereco
                a(href="mailto:contato@gtcompany.com.br", target="_blank").email 
                    strong contato@gtcompany.com.br
                a(href="tel:5504130295882", target="_blank").telefone 
                    | +55 41 
                    strong 3029 5882
                p.address
                    | Alameda Dr. Carlos de Carvalho, 431 - 16º Andar
</template>

<script>
import qs from 'qs';
import { mask } from 'vue-the-mask';

export default {
    directives: {
        mask
    },
    data() {
        return {
            buttonText: "Enviar",
            buttonDisabled: false,
            formulario: {
                nome: '',
                email: '',
                telefone: '',
                mensagem: ''
            },
            errors: {}
        }
    },
    methods: {
        sendFormulario() {
            this.buttonDisabled = true;
            this.buttonText = "Enviando...";
            this.$axios
                .post(`${process.env.VUE_APP_API_ROUTE}/contact`, qs.stringify(this.formulario))
                // eslint-disable-next-line
                .then(response => {
                    this.buttonText = "Enviado!";
                    this.formulario = {
                        nome: '',
                        email: '',
                        telefone: '',
                        mensagem: ''
                    }
                })
                .catch(error => {
                    if (error.response.status == 422) {
                        this.buttonText = "Verifique os campos"
                        this.errors = error.response.data.errors;
                    } else {
                        this.buttonText = "Ocorreu um erro. Tente mais tarde"
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        this.buttonDisabled = false;
                        this.buttonText = "Enviar";
                    }, 4500)
                })
        },
        removeError(field) {
            delete this.errors[field];
            this.$forceUpdate();
        }
    },
}
</script>

<style lang="stylus" scoped src="./Contato.styl"></style>